"use client";

import React, { useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";

const Pagination = () => {
  const router = useRouter();
  const searchParams = useSearchParams();

  let currentPage = Number(searchParams?.get("page")) || 1;
  currentPage = currentPage > 1 ? currentPage : 1;

  const isFirstPage = currentPage === 1;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        if (isFirstPage) {
          return;
        }
        void router.push(`/?page=${currentPage - 1}`);
      }
      if (event.key === "ArrowRight") {
        void router.push(`/?page=${currentPage + 1}`);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentPage, isFirstPage, router, searchParams]);

  return (
    <div className={"flex space-x-2"}>
      <Link
        href={`/?page=${currentPage - 1}`}
        className={`bg-gray-200 px-4 py-2 rounded-md ${
          isFirstPage && "pointer-events-none"
        }`}
      >
        {"<"}
      </Link>
      <Link
        href={`/?page=${currentPage + 1}`}
        className={"bg-gray-200 px-4 py-2 rounded-md "}
      >
        {">"}
      </Link>
    </div>
  );
};

export default Pagination;
