"use client";
import { FC, useState } from "react";
import { IMention } from "../types/mentionWithAttachments";
import { decode } from "html-entities";
import dynamic from "next/dynamic";

const DateString = dynamic(() => import("./DateString"), {
  ssr: false,
});

export const MentionCard: FC<{
  mention: IMention;
}> = ({ mention }) => {
  const [isMore, setIsMore] = useState(false);
  // console.log({ mention });
  let text = decode(mention.text).replaceAll("<br>", "\n");
  const isLong = text.length > 200;

  const getMaxWidth = (attachments: any) => {
    const length = attachments.length;
    if (length === 1) return "100%";
    if (length === 2) return "50%";
    if (length === 3) return "33%";
    if (length === 4) return "50%";
    if (length === 5) return "33%";
    if (length >= 6) return `${(100 / length) * 2}%`;
  };

  return (
    <div
      className={
        "max-w-[400px] w-full relative p-4 border bg-white shadow-xl flex flex-col"
      }
    >
      {/*// @ts-ignore*/}
      {mention?.meta?.raw?.action === "delete" && (
        <div className={"absolute top-0 right-0 bg-red-600 text-white px-1"}>
          Удалено
        </div>
      )}
      <div className={"flex pb-2"}>
        <div
          className={
            "bg-red-600 justify-start text-white text-xs px-1 rounded items-start"
          }
        >
          {mention.keywords.join("\n")}
        </div>
      </div>
      <div
        className={`whitespace-pre-line text-xs overflow-hidden text-ellipsis ${
          isLong && !isMore && "overflow-hidden h-20"
        }`}
      >
        {text}
        {mention.mentionType === "share" && mention?.attachments && (
          <div
            className={
              "flex ml-3 pl-1 py-1 border-l mt-2 max-h-20 overflow-ellipsis"
            }
          >
            {/* @ts-ignore*/}
            {mention?.meta?.raw?.shared_post_text}
          </div>
        )}
      </div>
      {isLong && (
        <button
          className={"text-xs text-gray-500 self-end pt-2"}
          onClick={() => setIsMore(!isMore)}
        >
          {isMore ? "Скрыть" : "Показать полностью"}
        </button>
      )}
      <div
        title={"тип упоминания"}
        className={
          "absolute -bottom-2.5 left-4 shadow-xl border bg-white px-2 text-sm text-gray-500"
        }
      >
        {mention.mentionType}
      </div>
      <div className={`flex flex-wrap justify-between pt-4`}>
        {mention?.attachments &&
          mention.attachments.map((attachment, index) => (
            <div
              className={"p-0"}
              key={index}
              style={{
                maxWidth: getMaxWidth(mention.attachments),
              }}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className={" object-cover h-full"}
                src={
                  attachment.photo?.photo_604 ||
                  attachment.video?.photo_800 ||
                  attachment.doc?.preview?.photo?.sizes[0].src ||
                  attachment.link?.photo?.photo_604 ||
                  "https://vk.com/images/camera_50.png"
                }
                alt={attachment.type}
              />
              {attachment.type !== "photo" && attachment.type}
            </div>
          ))}
      </div>
      <div className={"absolute -bottom-2.5 right-4 shadow-xl"}>
        <a
          href={mention.source}
          target={"_blank"}
          rel={"noreferrer"}
          className={"border bg-white px-2 text-sm text-gray-500"}
        >
          <DateString date={mention.createdAt} />
        </a>
      </div>
    </div>
  );
};
